import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import QRCode from 'qrcode.react';
import Processing from 'components/cmp_processing';
import datelib from 'libs/date-lib';
import 'i18n';

import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import MDL_EDIT_QR_CODE from './mdl_edit_qr_code/mdl_edit_qr_code';


function CRD_QR_CODE({ credential_id }) {

//  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_records, set_records ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'na' });
    const [ var_qr_link_download, set_qr_link_download ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);

    const [ var_selected_qr_code, set_selected_qr_code ] = useState(null);
    const [ var_edit_qr_code_open, set_edit_qr_code_open ] = useState(false);
    const [ var_delete_confirmation_open, set_delete_confirmation_open ] = useState(false);

    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (credential_id) {
            set_message_text(null);
            set_message_type(null);
            set_qr_link_download(null);
            set_processing(false);
            set_edit_qr_code_open(false);
            set_delete_confirmation_open(false);
            populate_records();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ credential_id ]);

    useEffect(() => {
        if (var_qr_link_download) {
            let canvas = document.getElementById('qr_code_canvas');
            let url = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            let anchor_element = document.createElement('a');
            anchor_element.href = url;
            anchor_element.download = 'QR code.png';
            document.body.appendChild(anchor_element);
            anchor_element.click();
            document.body.removeChild(anchor_element);
            set_qr_link_download(null);
        }
    }, [ var_qr_link_download ]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_records(populateconfig, focuselement_id) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);

        try {
            let results = await API_get_org_credential_credit_tracking_qr_codes();
            set_totalrows(results.length);
            set_records(results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#crd_orgcredential_qr_code').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_credential_credit_tracking_qr_codes() {
        return API.get('credentials', '/get-org-credential-credit-tracking-qr-codes/' + credential_id, {
                queryStringParameters: {
                    tz: datelib.timezone
                }
            }
        );
    }

    function API_put_org_credential_credit_tracking_qr_code_archive(id) {
        return API.put('credentials', `/put-org-credential-credit-tracking-qr-code-archive/${id}`);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_create_edit_qr_code(item) {
        set_selected_qr_code(item);
        set_edit_qr_code_open(true);
    }

    function onChange_qr_code() {
        populate_records();
        set_edit_qr_code_open(false);
    }

    function onClick_download_qr_code(id) {
        set_qr_link_download(`${window.location.protocol}//${window.location.host}/login/qr/${id}`);
    }

    function onClick_delete_qr_code(item) {
        set_selected_qr_code(item);
        set_delete_confirmation_open(true);
    }

    async function onDelete_qr_code() {
        set_processing(true);
        try {
            set_delete_confirmation_open(false);
            await API_put_org_credential_credit_tracking_qr_code_archive(var_selected_qr_code.id);
            populate_records();
        } catch {}
        set_processing(false);
    }


    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_orgcredential_qr_code' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('QR code')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                        <Button id='btn_create_qr_code' className='primary' onClick={onClick_create_edit_qr_code}>{t('Create QR code')}</Button>
                    </div>
                </div>

                <div className='content__description text--sm-regular'>
                    {t('QR codes enable individuals to scan and claim credits for this credential.')}
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} warning={var_message_type === 'WARNING'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'warning'} className='icon' />}
                        header={var_message_text} />
                }

                <Table id='qr_codes' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={false} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={() => {}}
                       downloadname={''} downloadfunction={() => {}}
                       onChange={populate_records} hide_tablecontrols={true}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{t('QR code')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('Credits')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('Expires')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_records.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    <Button id={`btn_download_${item.id}`} className='tertiary text--anchor' onClick={() => onClick_download_qr_code(item.id)}>
                                        {t('Download QR code')}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell>
                                    <Button id={`btn_edit_credit_value_${item.id}`} className='tertiary text--anchor' onClick={() => onClick_create_edit_qr_code(item)}>
                                        {item.credit_value}
                                    </Button>
                                </Table.Cell>
                                <Table.Cell className='cell__icon--right'>
                                    <Button id={`btn_edit_expiry_date_${item.id}`} className='tertiary text--anchor' onClick={() => onClick_create_edit_qr_code(item)}>
                                        {datelib.epoch_to_date_string(item.expiry_date, false)}
                                    </Button>
                                    <Button className='tertiary' onClick={() => onClick_delete_qr_code(item)}>
                                        <Icon name='delete' className='color--primary-500' />
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                <div className='card__footer--mobilebtns'>
                    <Button className='primary' onClick={onClick_create_edit_qr_code}>{t('Create QR code')}</Button>
                </div>

                <Processing display={var_processing} processingtext={t('Processing')} />

            </div>

            {var_qr_link_download && <div className='display-none'>
                <QRCode
                    className='qrcode'
                    id='qr_code_canvas'
                    value={var_qr_link_download}
                    size={200}
                    level='H'
                />
            </div>}

            <CMP_CONFIRMATION
                display={var_delete_confirmation_open}
                title={t('Delete QR code')}
                message={t('Are you sure you want to delete this QR code? This action cannot be undone.')}
                positive_option={t('Delete')}
                negative_option={t('Cancel')}
                onConfirm={onDelete_qr_code}
                onCancel={() => set_delete_confirmation_open(false)}
            />

            <MDL_EDIT_QR_CODE
                display={var_edit_qr_code_open}
                onClose={() => set_edit_qr_code_open(false)}
                credential_id={credential_id}
                qr_code_id={var_selected_qr_code?.id}
                onChange={onChange_qr_code}
            />
        </>
    )

}

CRD_QR_CODE.propTypes = {
    credential_id: propTypes.string.isRequired
};

export default CRD_QR_CODE;