import React, { useEffect, useState } from 'react';
import form_helper from 'libs/form-lib';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_DETAILSGROUP, FORM_FILEUPLOAD, FORM_INPUT, FORM_TEXTAREA, FORM_TOGGLE, TOGGLE } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import 'i18n';

import MDL_CERTIFICATE_PREVIEW from '../../credential_details/tab_details/crd_details/mdl_certificate_preview/mdl_certificate_preview';



export default function CRD_DETAILS({ step, onChange_step, credential, onChange_credential, onChange_processing, display_processing }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_opened, set_opened ] = useState(false)
    const [ var_errors, set_errors ] = useState([]);
    const [ var_fileupload_status, set_fileupload_status ] = useState(null);
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_show_checkmark, set_show_checkmark ] = useState(false);
    const [ var_preview_open, set_preview_open ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_credential_image_preview, set_credential_image_preview ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_errors([]);
        if (step === 'DETAILS') {
            set_opened(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (var_fileupload_status === 'UPLOAD COMPLETE') {
            onChange_step('SETTINGS');
            onChange_processing(false);
        } else if (var_fileupload_status === 'UPLOAD FAIL') {
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileupload_status]);

    useEffect(() => {
        if (form_helper.validate_required_string(credential.name)) {
            set_show_checkmark(true);
        } else {
            set_show_checkmark(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credential]);

    useEffect(() => {
        if (!var_preview_open && var_focuselement?.id === 'crd_create1') {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_preview_open]);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        onChange_step('DETAILS');
    }

    function onChange_input(event, { name, value }) {
        let updated_credential = {...credential};
        if (name === 'has_prequalifier' && value === 'YES') {
            updated_credential.status = 'PENDING';
            updated_credential.auto_verify = 'NO';
            updated_credential.issuedrequired = 'NO';
            updated_credential.credits_required_for_issuance = 'NO';
            updated_credential.allow_individual_to_update_credit_tracking = 'NO';
        } else if (name === 'credits_required_for_issuance' && value === 'YES') {
            if (value === 'YES') {
                updated_credential.status = 'PENDING';
                updated_credential.auto_verify = 'NO';
                updated_credential.issuedrequired = 'NO';
                updated_credential.has_prequalifier = 'NO';
            } else {
                updated_credential.allow_individual_to_update_credit_tracking = 'NO';
            }
        }
        updated_credential[name] = value;
        onChange_credential(updated_credential);
        set_errors(var_errors.filter(error => error.property !== name));
    }

    async function onChange_file(details) {
        let base64image = await new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.readAsDataURL(details.file_object)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        });
        onChange_credential({ ...credential, imagefile: details.filename, submittedfilename: details.display_name, imagefile_object: details.file_object });
        set_credential_image_preview(base64image);
    }

    async function onClick_nextstep() {
        set_save_error(null);
        let updated_credential = {...credential};
        let errors = [];
        //validation
        if (!form_helper.validate_required_string(credential.name)) {
            errors.push({ property: 'name', description: t('Credential name is required') });
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(updated_credential, 'name');
        form_helper.cleanse_string_property(updated_credential, 'code');
        form_helper.cleanse_string_property(updated_credential, 'description');
        form_helper.cleanse_string_property(updated_credential, 'skills');
        onChange_credential(updated_credential);

        // save logo
        if (var_fileupload_status === 'UPLOAD READY') {
            onChange_processing(true);
            set_fileupload_status('START UPLOAD');
        } else {
            onChange_step('SETTINGS');
        }
    }



    // RENDER APP ======================================================================================================

    return (
        <>
        <div className='card rounded-lg shadow-sm' id='crd_create1'  tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__icon'>
                    {(var_opened && step !== 'DETAILS' && var_show_checkmark) ?
                        <Icon name='checkmark' className='icon__checkmark' alt='checkmark icon' />
                        :
                        <div className='card__header__icon__step text--xl-bold icon--dark_gray'>1</div>
                    }
                </div>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_credential_details'>{t('Credential details')}</div>
                    {(!var_opened || step === 'DETAILS') &&
                        <div className='text--sm-regular'>{t('The name, description, skills, and image for the credential.')}</div>
                    }
                </div>
                <div className='card__header__right card__header__btns'>
                    {(var_opened && step !== 'DETAILS') &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {(step !== 'DETAILS' && var_opened) ?
                <>
                    <div className='card__content create_summary'>
                        <FORM_DETAILSGROUP label={t('Name')} value={credential.name} />
                        <FORM_DETAILSGROUP label={t('Credential code')} value={credential.code} />
                        <FORM_DETAILSGROUP label={t('Description')} value={credential.description} />
                        <FORM_DETAILSGROUP label={t('Skills')} value={credential.skills} />
                        <FORM_DETAILSGROUP label={t('Credential certificate')} value={() => {set_focuselement(document.activeElement); set_preview_open(true)}} type='internallink' linktext={t('Preview credential certificate')} />
                        <FORM_DETAILSGROUP label={t('Credential image')} value={credential.imagefile} type='logo'
                            logo_details={{ targetid: credential.id, classification: 'course', filename: var_credential_image_preview, alt: t('certificate logo') }}
                        />
                        <FORM_DETAILSGROUP label={t('Pre-qualifications')} value={credential.has_prequalifier === 'YES' ? t('Yes') : t('No')} />
                        {credential.has_prequalifier !== 'YES' && credential.can_select_credit_tracking === 'YES' &&
                            <FORM_DETAILSGROUP label={t('Credit tracking')} value={credential.credits_required_for_issuance === 'YES' ? t('Yes') : t('No')} />
                        }
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={() => onClick_edit()}>{t('Edit')}</Button>
                    </div>

                    <MDL_CERTIFICATE_PREVIEW credential={credential} is_open={var_preview_open} onClose={() => set_preview_open(false)} />

                </>
                : step === 'DETAILS' ?
                    <>
                        <Form className='card__content' id='form_cr8credential_step1' aria-labelledby='hdr_credential_details'>

                            {var_save_error &&
                                <Message error header={var_save_error} />
                            }

                            {var_errors.length > 0 &&
                                <Message
                                    error icon={<Icon name='error' className='icon'/>}
                                    header={t('There are some errors with your inputs')}
                                />
                            }

                            <FORM_INPUT
                                property='name'
                                label={t('Name')}
                                value={credential.name}
                                onChange={onChange_input}
                                placeholder={t('Credential name')}
                                maxLength={255}
                                disabled={false}
                                errors={var_errors}
                                description=''
                            />

                            <FORM_INPUT
                                property='code'
                                label={t('Credential code (optional)')}
                                value={credential.code}
                                onChange={onChange_input}
                                placeholder={t('Credential code')}
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                                description={t('Specified by an organization\'s credential catalogue; the credential code is a method to identify the credential\'s topic and to distinguish it from other credentials in that subject area.')}
                            />

                            <FORM_TEXTAREA
                                property='description'
                                label={t('Description (optional)')}
                                value={credential.description}
                                onChange={onChange_input}
                                placeholder={t('Description')}
                                maxLength={1000}
                                disabled={false}
                                errors={var_errors}
                                description={t('Describe the qualification, competence, or authority issued to an individual holding this credential. This description will be displayed on the public credential certificate.')}
                            />

                            <FORM_TEXTAREA
                                property='skills'
                                label={t('Skills (optional)')}
                                value={credential.skills}
                                onChange={onChange_input}
                                placeholder={t('Skills')}
                                maxLength={500}
                                disabled={false}
                                errors={var_errors}
                                description={t('The applicable skills developed by an individual who has earned this credential. The skills will be displayed on the public credential certificate.')}
                            />

                            <FORM_FILEUPLOAD
                                label={t('Credential image (optional)')}
                                property='image'
                                onChange={onChange_file}
                                single_or_multiple='SINGLE'
                                var_fileupload_status={var_fileupload_status}
                                set_fileupload_status={set_fileupload_status}
                                uploaddirectory={'courses'}
                                allowed_fileextensions={['png', 'jpg', 'jpeg']}
                                description={t('The image will be displayed on the credential certificate when a description or skill has been provided for the credential.')}
                                instructions={t('Only JPG and PNG file types are accepted and should be a minimum 90x90 pixels.')}
                                errors={var_errors}
                            />

                            <FORM_TOGGLE
                                label={t('Pre-qualifications')}
                                value={credential.has_prequalifier}
                                options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]}
                                onChange={(value) => onChange_input(null, { name: 'has_prequalifier', value })}
                                disabled={credential.credits_required_for_issuance === 'YES'}
                                description={t('This credential has pre-qualification requirements, which must be completed by the holder for it to be valid. These may include attestations, document acknowledgments, holding another valid credential, or a combination of these.')}
                                instructions={<>
                                    <div style={{whiteSpace: 'pre'}} className='text--sm-medium color--gray-900'>{t('Note') + ': '}</div>
                                    <div className='color--gray-900'>{t('Once the credential is created, pre-qualifications cannot be disabled.')}</div>
                                </>}
                                message_type={(credential.credits_required_for_issuance === 'YES') ? 'INFO' : null}
                                message_text={t("A credential cannot have both 'Pre-qualifications' and 'Credit tracking' enabled. Please disable one option to enable the other.")}
                            />

                            {credential.can_select_credit_tracking === 'YES' &&
                                <FORM_TOGGLE
                                    label={t('Credit tracking')}
                                    value={credential.credits_required_for_issuance}
                                    options={[{value: 'YES', text: t('Yes')}, { value: 'NO', text: t('No') }]}
                                    onChange={(value) => onChange_input(null, { name: 'credits_required_for_issuance', value })}
                                    disabled={credential.has_prequalifier === 'YES'}
                                    description={t('Enable the tracking of completed credits toward a predefined goal.')}
                                    instructions={<>
                                        <div style={{whiteSpace: 'pre'}} className='text--sm-medium color--gray-900'>{t('Note') + ': '}</div>
                                        <div className='color--gray-900'>{t('Once the credential is created, credit tracking cannot be disabled.')}</div>
                                    </>}
                                    message_type={(credential.has_prequalifier === 'YES') ? 'INFO' : null}
                                    message_text={t("A credential cannot have both 'Pre-qualifications' and 'Credit tracking' enabled. Please disable one option to enable the other.")}
                                />
                            }

                            {credential.credits_required_for_issuance === 'YES' &&
                                <div className='detailsgroup__subgroup rounded-lg'>
                                    <div className='detailsgroup__subgroup__description text--sm-regular'>{t('Individuals can edit and submit credit updates.')}</div>
                                    <div className='detailsgroup__subgroup__content'>
                                        <div id='field__allow_individual_to_update_credit_tracking' className='detailsgroup__subgroup__content__item'>
                                            <TOGGLE
                                                value={credential.allow_individual_to_update_credit_tracking}
                                                options={[{value: 'YES', text: t('Yes')}, { value: 'NO', text: t('No') }]}
                                                onChange={(value) => onChange_input(null, { name: 'allow_individual_to_update_credit_tracking', value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                        </Form>
                        <div className='card__footer'>
                            <Button className='primary' onClick={onClick_nextstep}>{t('Continue to step 2')}</Button>
                        </div>

                    </>
                    : null
            }
            <Processing display={display_processing} processingtext='' />

        </div>
        </>
    );
}