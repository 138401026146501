import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import datelib from 'libs/date-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import Icon from 'components/cmp_icon';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import 'i18n';

import MDL_DOCVIEWER from 'components/cmp_docviewer/cmp_docviewer';
import MDL_ADD_HOLDER from './mdl_add_holder/mdl_add_holder';
import MDL_BATCH_CREDITS from './mdl_batch_credits/mdl_batch_credits';
import CMP_CREDENTIAL_STATUS from 'components/cmp_credential_status/cmp_credential_status';
import MDL_PREQUALIFIERS_DETAILS from './mdl_prequalifiers_details/mdl_prequalifiers_details';
import MDL_CREDIT_ACTIVITY_UPLOAD_DOCUMENT from './mdl_credit_activity_upload_document/mdl_credit_activity_upload_document';
import MDL_CREDIT_ACTIVITY_MANUAL_ENTRY from './mdl_credit_activity_manual_entry/mdl_credit_activity_manual_entry';
import MDL_CREDIT_ACTIVITY_SUMMARY from './mdl_credit_activity_summary/mdl_credit_activity_summary';
import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';
import CMP_POPUP_BUTTON_OPTIONS from 'components/cmp_popup_button_options';

export default function TAB_HOLDERS({ credential_id, credential_details, credential_settings }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_holders, set_holders ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, limit: 10, sortby: 'individual_name' });
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_holderstatus, set_holderstatus ] = useState('ALL');
    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);

    const [ var_verificationpopup_id, set_verificationpopup_id ] = useState(null);

    const [ var_docviewer_id, set_docviewer_id ] = useState(null);
    const [ var_docviewer_open, set_docviewer_open ] = useState(false);

    const [ var_addholder_open, set_addholder_open ] = useState(false);
    const [ var_mdl_batch_credits_open, set_mdl_batch_credits_open ] = useState(false);
    const [ var_individual_credential_id, set_individual_credential_id ] = useState(null);
    const [ var_individual_name, set_individual_name ] = useState(null);
    const [ var_prequalifiers_details_open, set_prequalifiers_details_open ] = useState(false);
    const [ var_mdl_summary_open, set_mdl_summary_open ] = useState(false);
    const [ var_mdl_upload_document_open, set_mdl_upload_document_open ] = useState(false);
    const [ var_mdl_manual_entry_open, set_mdl_manual_entry_open ] = useState(false);
    const [ var_target_popup_id, set_target_popup_id ] = useState(false);
    const [ var_mdl_summary_active_toggle, set_mdl_summary_active_toggle ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        set_refreshtable(!var_refreshtable);
        populate_holders({ ...var_populateconfig, offset: 0, filter: {} }); // repopulate using offset 0 and no filter
        set_focuselement(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_holderstatus]);

    useEffect(() => {
        if (!var_addholder_open && ['btn_addholder', 'btn_addholder_mobile'].includes(var_focuselement?.id)) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_addholder_open]);

    useEffect(() => {
        if (!var_mdl_batch_credits_open && ['btn_batch_credits', 'btn_batch_credits_mobile'].includes(var_focuselement?.id)) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_batch_credits_open]);

    useEffect(() => {
        if (!var_prequalifiers_details_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_prequalifiers_details_open]);

    useEffect(() => {
        if (!var_mdl_summary_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        } else {
            document.getElementById('crd_creddetails_holders').focus();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_summary_open]);

    useEffect(() => {
        if (!var_mdl_upload_document_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_upload_document_open]);

    useEffect(() => {
        if (!var_mdl_manual_entry_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_manual_entry_open]);

    useEffect(() => {
        if (!var_docviewer_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_creddetails_holders').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_docviewer_open]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_holders(populateconfig, focuselement_id) {
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_org_credential_holders(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_holders(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);

            if (focuselement_id) {
                let observer = new MutationObserver(function() {
                    let focuselement = document.querySelector(`#${focuselement_id}`);
                    if (document.contains(focuselement)) {
                        focuselement.focus();
                        observer.disconnect();
                    } else {
                        document.querySelector('#crd_creddetails_holders').focus();
                        observer.disconnect();
                    }
                });
                observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
            } else if (var_focuselement?.id && !var_mdl_summary_open) {
                let observer = new MutationObserver(function() {
                    let focuselement = document.querySelector(`#${var_focuselement.id}`);
                    if (document.contains(focuselement)) {
                        focuselement.focus();
                        observer.disconnect();
                    } else {
                        document.querySelector('#crd_creddetails_holders').focus();
                        observer.disconnect();
                    }
                });
                observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
            }
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_org_credential_holders(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_holders(limit, offset) {

        try {
            let {sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_org_credential_holders(limit, offset, sortby, sortorder, filter)).results;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_credential_holders(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('credentials', '/get-org-credential-holders/' + credential_id + '/' + var_holderstatus,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone
                },
                body: filter
            }
        );
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_holderstatus(status, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_holderstatus(status);
    }

    function onClick_addholder() {
        set_focuselement(document.activeElement);
        set_message_text(null);
        set_addholder_open(true);
    }

    function onClick_batch_credits() {
        set_focuselement(document.activeElement);
        set_message_text(null);
        set_mdl_batch_credits_open(true);
    }

    function onChange_addholder() {
        set_message_text(null);
        populate_holders();
        set_addholder_open(false);
    }

    function onChange_batch_credits() {
        set_message_type('SUCCESS');
        set_message_text(t('Credits have been successfully updated.'));
        populate_holders();
        set_mdl_batch_credits_open(false);
    }

    function onClick_individual(individual_id) {
        set_message_text(null);
        navigate('/individuals/' + individual_id);
    }

    function onClick_document(id) {
        set_focuselement(document.activeElement);
        set_docviewer_id(id);
        set_docviewer_open(true);
    }

    function onClick_credential_status(id) {
        set_message_text(null);
        set_verificationpopup_id(id);
    }

    function onClose_credential_status() {
        set_verificationpopup_id(null);
        set_message_text(null);
        set_message_type(null);
    }

    function onChange_credential_status(focuselement_id) {
        set_verificationpopup_id(null);
        populate_holders(null, focuselement_id);
    }

    function onClick_prequalifiers(id, name) {
        set_verificationpopup_id(null);
        set_focuselement(document.activeElement);
        set_individual_credential_id(id);
        set_individual_name(name);
        set_prequalifiers_details_open(true);
    }

    function onClick_upload_document(id, name) {
        set_target_popup_id(null);
        set_individual_credential_id(id);
        set_individual_name(name);
        set_mdl_upload_document_open(true);
    }

    function onClick_manual_entry(id, name) {
        set_target_popup_id(null);
        set_individual_credential_id(id);
        set_individual_name(name);
        set_mdl_manual_entry_open(true);
    }

    function onClick_credit_activity_details(id, name, active_toggle) {
        set_target_popup_id(null);
        set_individual_credential_id(id);
        set_individual_name(name);
        set_mdl_summary_active_toggle(active_toggle);
        set_mdl_summary_open(true);
    }

    function onClick_summary(id) {
        set_target_popup_id(id);
        set_focuselement(document.activeElement);
    }

    function onChange_upload_document() {
        set_mdl_upload_document_open(false);
        populate_holders();
    }

    function onChange_manual_entry() {
        set_mdl_manual_entry_open(false);
        populate_holders();
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_creddetails_holders' tabIndex='0'>
                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div className='text--xl-medium'>{t('Holders')}</div>
                    </div>
                    {credential_details.status === 'ACTIVE' &&
                    <div className='card__header__right card__header__btns'>
                        <Button id='btn_addholder' className='primary' onClick={() => onClick_addholder()}>{t('Add holder')}</Button>
                        {credential_settings.credits_required_for_issuance === 'YES' &&
                            <Button id='btn_batch_credits' className='secondary' onClick={onClick_batch_credits}>{t('Batch credits')}</Button>
                        }
                    </div>
                    }
                </div>

                {var_message_text &&
                    <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                        icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                        header={var_message_text} />
                }

                <Table id='holders' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                       populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                       downloadname={t('holders')} downloadfunction={download_holders}
                       onChange={populate_holders}>
                    <Table.OptionsButtons>
                        <TOGGLE
                            value={var_holderstatus}
                            onChange={onClick_holderstatus}
                            options={(() => {
                                let options = [{value: 'ALL', text: t('All')}, {value: 'VALID', text: t('Valid')}, {value: 'PENDING', text: t('Pending')}];
                                if (credential_details.has_prequalifier === 'YES' || credential_settings.credits_required_for_issuance === 'YES') {
                                    options = options.filter(item => item.value !== 'PENDING');
                                    options.push({value: 'IN_PROGRESS', text: t('In progress')});
                                }
                                if (credential_details.expires === 'YES') {
                                    options.push({value: 'EXPIRING', text: t('Expiring')});
                                }
                                options.push({value: 'INVALID', text: t('Invalid')});
                                return options;
                                })()} />
                    </Table.OptionsButtons>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='verification_status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='individual_name' datatype='text' filtertype='text'>{t('HOLDER')}</Table.HeaderCell>
                            <Table.HeaderCell field='email' datatype='text' filtertype='text'>{t('EMAIL')}</Table.HeaderCell>
                            <Table.HeaderCell field='organization' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                            {credential_details.has_prequalifier === 'YES' &&
                                <Table.HeaderCell field='completed_prequalifiers' datatype='number' filtertype='number'>{t('PRE-QUALIFIERS')}</Table.HeaderCell>
                            }
                            <Table.HeaderCell field='issued' datatype='date' filtertype='date'>{t('ISSUED')}</Table.HeaderCell>
                            {credential_details.expires === 'YES' &&
                                <Table.HeaderCell field='expires' datatype='date' filtertype='date'>{t('EXPIRES')}</Table.HeaderCell>
                            }
                            {credential_details.has_prequalifier === 'NO' && credential_settings.credits_required_for_issuance !== 'YES' &&
                                <Table.HeaderCell field='submittedfilename' datatype='text' filtertype='text'>{t('ATTACHMENT')}</Table.HeaderCell>
                            }
                            {(var_holderstatus !== 'PENDING' && credential_details.has_prequalifier === 'NO' && credential_settings.credits_required_for_issuance !== 'YES') &&
                                <Table.HeaderCell field='confirmation_status_display' datatype='text' filtertype='option'>{t('CONFIRMATION')}</Table.HeaderCell>
                            }
                            {credential_settings.credits_required_for_issuance === 'YES' &&
                                <Table.HeaderCell field='current_credits_obtained' datatype='text' filtertype='text'>{t('CREDITS')}</Table.HeaderCell>
                            }
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_holders.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    <CMP_CREDENTIAL_STATUS
                                        can_manage={false}
                                        can_verify={credential_details.has_write_access === 'YES'}
                                        can_update={credential_details.has_write_access === 'YES' &&
                                            ((credential_details.has_prequalifier !== 'YES' && credential_settings.credits_required_for_issuance !== 'YES') || credential_details.custom_fields?.length > 0)}
                                        has_prequalifier={credential_details.has_prequalifier === 'YES'}
                                        credits_required_for_issuance={credential_settings.credits_required_for_issuance === 'YES'}
                                        is_open={item.id === var_verificationpopup_id}
                                        credential={{ ...item, credential_id: credential_details.id, credential_name: credential_details.name, issuer_name: credential_details.issuer_name }}
                                        onClose={onClose_credential_status}
                                        onOpen={onClick_credential_status}
                                        onChange={onChange_credential_status}
                                        onMessage={(type, text) => { set_message_type(type); set_message_text(text); }}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <CMP_VERIFIED_IDENTITY_DISPLAY
                                        individual_name={item.individual_name}
                                        onClick_individual={() => onClick_individual(item.individual_id)}
                                        verified_identity_credential_id={item.identity_verification_credential_id}
                                    />
                                </Table.Cell>
                                <Table.Cell>{item.email}</Table.Cell>
                                <Table.Cell>{item.organization}</Table.Cell>
                                {credential_details.has_prequalifier === 'YES' &&
                                    <Table.Cell>
                                        <Button className='tertiary text--anchor' id={`btn_completed_prequalifiers_${item.id}`} onClick={() => onClick_prequalifiers(item.id, item.individual_name)}>
                                            {item.completed_prequalifiers}
                                        </Button>
                                    </Table.Cell>
                                }
                                <Table.Cell>{datelib.epoch_to_date_string(item.issued)}</Table.Cell>
                                {credential_details.expires === 'YES' &&
                                    <Table.Cell>{datelib.epoch_to_date_string(item.expires)}</Table.Cell>
                                }
                                {credential_details.has_prequalifier === 'NO' && credential_settings.credits_required_for_issuance !== 'YES' &&
                                    <Table.Cell>
                                        {item.submittedfilename &&
                                            <Button className='tertiary' onClick={() => onClick_document(item.id)}>
                                                <Icon name='attachment' className='icon__attachment'/>
                                            </Button>
                                        }
                                    </Table.Cell>
                                }
                                {(var_holderstatus !== 'PENDING' && credential_details.has_prequalifier === 'NO' && credential_settings.credits_required_for_issuance !== 'YES') &&
                                    <Table.Cell>{item.confirmation_status_display}</Table.Cell>
                                }
                                {credential_settings.credits_required_for_issuance === 'YES' &&
                                    <Table.Cell className='text--anchor'>
                                        <CMP_POPUP_BUTTON_OPTIONS
                                            target_id={item.id}
                                            is_open={item.id === var_target_popup_id}
                                            onClose={() => set_target_popup_id(null)}
                                            onOpen={() => onClick_summary(item.id)}
                                            trigger_button_label={`${item.current_credits_obtained}/${item.credits_required}`}
                                            popup_title={t('Credits')}
                                            button_options={[
                                                ...credential_settings.allow_credit_activity_ocr_scan === 'YES' ? [
                                                    { onClick: () => onClick_upload_document(item.id, item.individual_name), label: t('Add credits: Upload document') }
                                                ] : [],
                                                { onClick: () => onClick_manual_entry(item.id, item.individual_name), label: t('Add credits: Manual entry') },
                                                { onClick: () => onClick_credit_activity_details(item.id, item.individual_name, 'DETAILVIEW'), label: t('Edit credits') },
                                                { onClick: () => onClick_credit_activity_details(item.id, item.individual_name, 'SUMMARY'), label: t('View credit summary') }
                                            ]}
                                        />
                                    </Table.Cell>
                                }
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                {credential_details.status === 'ACTIVE' &&
                    <div className='card__footer--mobilebtns'>
                        <Button id='btn_addholder_mobile' className='primary' onClick={() => onClick_addholder()}>{t('Add holder')}</Button>
                        {credential_settings.credits_required_for_issuance === 'YES' &&
                            <Button id='btn_batch_credits_mobile' className='secondary' onClick={onClick_batch_credits}>{t('Batch credits')}</Button>
                        }
                    </div>
                }

            </div>

            <MDL_DOCVIEWER
                targetid={var_docviewer_id}
                classification='credential'
                is_open={var_docviewer_open}
                onClose={() => set_docviewer_open(false)}
            />

            <MDL_ADD_HOLDER
                is_open={var_addholder_open}
                onClose={() => set_addholder_open(false)}
                credential_details={credential_details}
                credential_settings={credential_settings}
                onChange={onChange_addholder}
            />

            <MDL_BATCH_CREDITS
                is_open={var_mdl_batch_credits_open}
                onClose={() => set_mdl_batch_credits_open(false)}
                credential_details={credential_details}
                onChange={onChange_batch_credits}
            />

            <MDL_PREQUALIFIERS_DETAILS
                is_open={var_prequalifiers_details_open}
                individual_credential_id={var_individual_credential_id}
                holder_name={var_individual_name}
                onClose={() => set_prequalifiers_details_open(false)}
            />

            <MDL_CREDIT_ACTIVITY_SUMMARY
                is_open={var_mdl_summary_open}
                credential_id={credential_id}
                individual_credential_id={var_individual_credential_id}
                holder_name={var_individual_name}
                view_type_on_open={var_mdl_summary_active_toggle}
                onChange={populate_holders}
                onClose={() => set_mdl_summary_open(false)}
            />

            <MDL_CREDIT_ACTIVITY_UPLOAD_DOCUMENT
                is_open={var_mdl_upload_document_open}
                credential_id={credential_id}
                individual_credential_id={var_individual_credential_id}
                holder_name={var_individual_name}
                onChange={onChange_upload_document}
                onClose={() => set_mdl_upload_document_open(false)}
            />

            <MDL_CREDIT_ACTIVITY_MANUAL_ENTRY
                is_admin
                is_open={var_mdl_manual_entry_open}
                credential_id={credential_id}
                individual_credential_id={var_individual_credential_id}
                holder_name={var_individual_name}
                onChange={onChange_manual_entry}
                onClose={() => set_mdl_manual_entry_open(false)}
            />

        </>
    )

}
