import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import { TOGGLE } from 'components/cmp_form/cmp_form';
import { INPUT } from 'components/cmp_form/cmp_form';
import datelib from 'libs/date-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { useTranslation } from 'react-i18next';
import 'i18n';

import CMP_CREDIT_ACTIVITY_STATUS from 'components/cmp_credit_activity_status/cmp_credit_activity_status';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import CMP_CREDIT_ACTIVITY from 'components/cmp_credit_activity/cmp_credit_activity';

import './mdl_credit_activity_summary.css';

export default function MDL_CREDIT_ACTIVITY_SUMMARY({ is_open, holder_name, onClose, onChange, individual_credential_id, view_type_on_open = 'DETAILVIEW' }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_processing, set_processing ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_credential_credit_summary, set_credential_credit_summary ] = useState([]);
    const [ var_allow_individual_to_update_credit_tracking, set_allow_individual_to_update_credit_tracking ] = useState('NO');
    const [ var_view_type, set_view_type ] = useState(view_type_on_open);

    const [ var_credit_activity_entries, set_credit_activity_entries  ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'activity_date', sortorder: 'descending' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);

    const [ var_delete_confirmation_open, set_delete_confirmation_open ] = useState(false);
    const [ var_target_activity_id, set_target_activity_id ] = useState(null);
    const [ var_mdl_activity_details_open, set_mdl_activity_details_open ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);
    const [ var_save_error, set_save_error ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_modal) {

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal, var_view_type]);

    useEffect(() => {
        if (is_open) {
            // reset
            set_view_type(view_type_on_open);
            set_allow_individual_to_update_credit_tracking('NO');
            set_modal(document.querySelector('#mdl_summary'));
            view_type_on_open === 'DETAILVIEW' ? populate_details() : populate_summary();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        if (!var_mdl_activity_details_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_activity_details_open]);

        useEffect(() => {
            if (!var_delete_confirmation_open && var_focuselement) {
                if (var_focuselement.isConnected) {
                    var_focuselement.focus();
                    set_focuselement(null);
                } else {
                    document.getElementById('form_summary').focus();
                }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[var_delete_confirmation_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_summary() {
        set_processing(true);
        try {
            let result = await API_get_credential_credit_summary();
            set_credential_credit_summary(result.credit_summary);
            set_allow_individual_to_update_credit_tracking(result.allow_individual_to_update_credit_tracking);
        } catch (e) {
            console.log(e);
        }
        set_processing(false);
    }

    async function populate_details(populateconfig, focuselement_id) {
        set_save_error(null);
        set_ready(false);
        set_loadingerror(false);
        set_loading(true);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_credential_credit_activities(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_credit_activity_entries(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_loadingerror(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#form_summary').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_credential_credit_activities(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_credential_credit_activities(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_credit_summary() {
        return API.get('credentials', `/get-credential-credit-summary/${individual_credential_id}`);
    }

    function API_get_credential_credit_activities(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('credentials', `/get-credential-credit-activities/${individual_credential_id}`,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    tz: datelib.timezone,
                    filtername: filtername,
                    status: 'ALL'
                },
                body: filter
            }
        );
    }

    function API_delete_credential_credit_activity() {
        return API.del('credentials', '/delete-credential-credit-activity/' + var_target_activity_id,
            {
                queryStringParameters: {
                    tz: datelib.timezone
                }
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    async function onConfirm_delete() {
        set_delete_confirmation_open(false);
        set_save_error(null);
        set_processing(true);
        try {
            await API_delete_credential_credit_activity();
            onChange();
            populate_details();
        } catch (e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        set_processing(false);
    }

    function onClick_delete(id) {
        set_save_error(null);
        set_focuselement(document.activeElement);
        set_target_activity_id(id);
        set_delete_confirmation_open(true);
    }

    function onClick_activity(id) {
        set_save_error(null);
        set_focuselement(document.activeElement);
        set_target_activity_id(id);
        set_mdl_activity_details_open(true);
    }

    function onChange_activity_details() {
        set_mdl_activity_details_open(false);
        populate_details(null, var_focuselement?.id);
        onChange();
    }

    function onChange_view_type(view_type) {
        set_view_type(view_type);
        view_type === 'DETAILVIEW' ? populate_details() : populate_summary();
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_summary'
            dimmer='inverted'
            onClose={onClose}
            open={is_open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_summary'
        >

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_summary'>{holder_name}</div>
                </div>
            </div>

            <Form className='modal__content' id='form_summary' aria-labelledby='hdr_summary' tabIndex='0'>

                {var_save_error &&
                    <Message error header={var_save_error} />
                }

                <TOGGLE value={var_view_type} onChange={onChange_view_type} options={[{value: 'DETAILVIEW', text: t('Detail view')}, {value: 'SUMMARY', text: t('Summary')}]} />

                {var_view_type === 'SUMMARY' && var_allow_individual_to_update_credit_tracking === 'YES' &&
                    <Message info icon={<Icon name='info' className='icon' alt={t('info icon')} />} header={t('Pending credits are excluded from the summary.')} />
                }
                {var_view_type === 'SUMMARY' ?
                    <>
                        {var_credential_credit_summary.map(category => render_category(category))}
                    </>
                    :
                    <Table id='credit_activity_entries' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                        populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                        downloadname={t('Credit activities')} downloadfunction={download_records}
                        onChange={populate_details}>

                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                                <Table.HeaderCell field='activity_date' datatype='datetime' filtertype='date'>{t('DATE')}</Table.HeaderCell>
                                <Table.HeaderCell field='credential_credit_description' datatype='text' filtertype='text'>{t('DESCRIPTION')}</Table.HeaderCell>
                                <Table.HeaderCell field='credit_value' datatype='number' filtertype='number'>{t('CREDITS')}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {var_credit_activity_entries.map(item =>
                                <Table.Row key={item.id}>
                                    <Table.Cell>
                                        <CMP_CREDIT_ACTIVITY_STATUS
                                            is_open={false}
                                            activity={item}
                                            onChange={() => null}
                                            onMessage={() => null}
                                            onClose={() => null}
                                            onOpen={() => null}
                                            can_edit={false}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        {datelib.epoch_to_date_string(item.activity_date, false)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {item.credential_credit_description}
                                    </Table.Cell>
                                    <Table.Cell className='cell__icon--right text--anchor'>
                                        <Button id='btn_current_credits' className='tertiary' onClick={() => onClick_activity(item.id)}>
                                            {item.credit_value}
                                        </Button>
                                        <Button id='btn_delete_activity' className='tertiary' onClick={() => onClick_delete(item.id)}>
                                            <Icon name='delete' className='color--primary-500' />
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                }
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>

            <CMP_CONFIRMATION
                display={var_delete_confirmation_open}
                title={t('Delete entry')}
                message={t('Are you sure you want to delete this entry? This action cannot be undone.')}
                positive_option={t('Delete')}
                negative_option={t('Cancel')}
                onConfirm={onConfirm_delete}
                onCancel={() => set_delete_confirmation_open(false)}
            />

            <CMP_CREDIT_ACTIVITY
                is_open={var_mdl_activity_details_open}
                onClose={() => set_mdl_activity_details_open(false)}
                activity_id={var_target_activity_id}
                onChange={onChange_activity_details}
                holder_name={holder_name}
                can_edit={true}
            />

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );

    function render_category(category) {
        let activities = var_credential_credit_summary.filter(item => item.parent_credential_credit_id === category.id);
        let category_current_credits = activities.reduce((accumulator, current) => current.current_credits ? accumulator + Number(current.current_credits) : accumulator, 0);
        return (
            (activities.length > 0) ?
                <div key={category.id} className='category_wrapper'>
                    <div className='category_header text--sm-medium'>
                        {category_current_credits >= category.credits_required
                            ? <Icon name='checkmark' className='color--green-500' alt={t('status icon')} />
                            : <Icon name='warning_circle' className='warning-icon--yellow' alt={t('status icon')} />
                        }
                        <div className='category_title'>{category.description}</div>
                        <div className='category_summary'>
                            {`${category_current_credits.toFixed(2)}/${category.credits_required}`}
                        </div>
                    </div>
                    {activities.map(item =>
                        <div key={item.id} className='item_wrapper'>
                            <div style={{ paddingRight: '1rem' }}>{item.description}</div>
                            <div className='item_credits'>
                                <INPUT
                                    property='current_credits'
                                    value={item.current_credits.toFixed(2)}
                                    disabled
                                    placeholder=''
                                />
                            </div>
                        </div>
                    )}
                </div>
            : (category.credits_required) ?
                <div key={category.id} className='category_wrapper'>
                    <div className='category_header text--sm-medium'>
                        {category.current_credits >= category.credits_required
                            ? <Icon name='checkmark' className='color--green-500' alt={t('status icon')} />
                            : <Icon name='warning_circle' className='warning-icon--yellow' alt={t('status icon')} />
                        }
                        <div className='category_title'>{t('Task')}</div>
                        <div className='category_summary'>
                            {`${category.current_credits}/${category.credits_required}`}
                        </div>
                    </div>
                    <div className='item_wrapper'>
                        <div style={{ paddingRight: '1rem' }}>{category.description}</div>
                        <div className='item_credits'>
                            <INPUT
                                property='current_credits'
                                value={category.current_credits.toFixed(2)}
                                disabled
                                placeholder=''
                            />
                        </div>
                    </div>
                </div>
            : null
        );
    }
}
